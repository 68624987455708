<!-- Header Banner -->
<!-- this component lists header elements that prompt users for specific actions based around their subscription -->
<template>
  <div class=" text-2xl md:text-xl lg:text-base">
    <template v-if="loading || !subscription"></template>
    <!-- banner to prompt a user to upgrade before trialing is over -->
    <div v-else-if="subscriptionTrialing && !cardExists" id="trialing" class="prompt-banner">
      <div class="flex justify-center my-2">
        <span>
          Continue saving time with Talkatoo after your trial.

          <button class="as-anchor font-bold no-underline pl-4" @click="toggleBillingModal"> Add payment information!</button>
        </span>
      </div>
    </div>
    <!-- banner to prompt a user to upgrade after their trial is over -->
    <div v-else-if="subscriptionTrialEnded" id="trial-ended" class="error-banner">
      <div class="flex justify-center my-2">
        <span>
          Your trial has ended, save more time with Talkatoo! <br class="visible sm:hidden" />
          <button v="else" class="as-anchor font-bold no-underline pl-4" @click="toggleSubscriptionModal"> Upgrade now!</button>
        </span>
      </div>
    </div>
    <!-- banner to prompt a user to upgrade after their trial is over -->
    <div v-else-if="subscriptionPaused" id="trial-ended" class="error-banner">
      <div class="flex justify-center my-2">
        <span class="font-bold">Your trial has ended!&nbsp; </span>
        <span>
          Keep saving time with Talkatoo! <br class="visible sm:hidden" />
          <button v="else" class="as-anchor font-bold no-underline pl-4" @click="toggleBillingModal"> Add payment information!</button>
        </span>
      </div>
    </div>
    <!-- banner to prompt a user that their payments are failing -->
    <div v-else-if="subscriptionPastDue" id="past-due" class="error-banner">
      <div class="flex flex-col lg:flex-row justify-center my-2">
        <span class="text-center lg:text-left my-auto">
          <span class="font-bold">Your subscription payment is past due!</span>
          Update your payment method to prevent service interruption.
        </span>
        <button v="else" class="as-anchor font-bold no-underline whitespace-nowrap py-2 pl-2" @click="toggleBillingModal">
          Update now!
        </button>
      </div>
    </div>
    <!-- banner to prompt a user that their payments are failing -->
    <div v-else-if="subscriptionOnHold" id="past-due" class="error-banner">
      <div class="flex flex-col lg:flex-row justify-center my-2">
        <span class="text-center lg:text-left">
          <span class="font-bold">Your subscription has been placed on hold!</span>
          You may reactivate your subscription, billing will resume and you may begin using Talkatoo again.
        </span>
        <button v="else" class="as-anchor font-bold no-underline whitespace-nowrap py-2 pl-2" @click="reactivateSubscription">
          Reactivate now!
        </button>
      </div>
    </div>
    <!-- banner to prompt a user that their subscription is canceled -->
    <div v-else-if="subscriptionCancelled" id="cancelled" class="error-banner">
      <div class="flex flex-col lg:flex-row justify-center my-2">
        <span class="text-center lg:text-left my-auto">
          <span class="font-bold">Your credit card has expired!</span>
          Please update it to ensure that your services are not interrupted.
        </span>
        <button v="else" class="as-anchor font-bold no-underline whitespace-nowrap py-2 pl-2" @click="toggleBillingModal">
          Update now!
        </button>
      </div>
    </div>
    <!-- banner for the case that a subscription's credit car is expiring -->
    <div v-else-if="!remindLater && cardExists && cardExpiring" id="expiring" class="error-banner">
      <div class="flex flex-wrap flex-col lg:flex-row justify-center text-center lg:text-left my-2">
        <div class="flex lg:flex-grow lg:order-1 mx-auto font-bold">
          The card you have on file will expire in {{ cardExpiryDays }} days.
        </div>
        <div class="order-2 lg:order-1 mx-auto">
          <button v="else" class="as-anchor font-bold no-underline" @click="toggleBillingModal">
            Update Payment Info!
          </button>
        </div>
        <div class="flex lg:flex-grow order-1 lg:order-2 mx-auto">
          Please update your payment info to avoid service interruptions.
        </div>
        <div class="order-2 lg:order-2 mx-auto mt-2 lg:mt-0">
          <button class="link-thin text-gray-500 no-underline text-sm md:text-base lg:text-xs my-1" @click="setRemindLater">
            Remind me later
          </button>
        </div>
      </div>
    </div>
    <!-- Banner displayed when a subscription is completely full and a user needs to upgrade to get more seats -->
    <div v-else-if="subscriptionFull" id="subscription-full" class="prompt-banner">
      <div class="flex justify-center my-2">
        <span v-if="numberOfUsers == 1"> Need to grow your team?
          <a class="inline-anchor pl-4" @click="toggleSubscriptionModal">Upgrade your plan now!</a>
        </span>
        <span v-else> Congrats! You've hit your user cap. Let's keep growing.
          <a class="inline-anchor pl-4" @click="toggleSubscriptionModal">Upgrade now!</a>
        </span>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "HeaderBanner",
  emits: ['reactivateSubscription', 'toggleBillingModalEvent', 'toggleShippingModalEvent', 'toggleSubscriptionModalEvent'],
  props: ["subscription", "loading", "filterList"],
  data () {
    return {
      remindLater: localStorage.getItem('remindLater') || false,
      filterSet: {},
    };
  },
  created () {
    // lets pages filter to the banners that are relevant to them
    // default set is all banners
    if (!this.filterList || this.filterList.length == 0) {
      this.filterSet = {
        trialing: true,
        trialEnded: true,
        pastDue: true,
        onHold: true,
        paused: true,
        cancelled: true,
        expiring: true,
        subscriptionFull: true,
      };
    } else {
      // reduce the list to an object for cleaner and faster referencing in computed methods
      this.filterSet = this.filterList.reduce((prev, x) => { prev[x] = true; return prev; }, {});
    }
  },
  computed: {

    userPerms () {
      return this.$store.getters.getUserPerms;
    },

    cardExists () {
      // shortstop on payment type.
      if (!this.subscription.payment_profile) {
        return false;
      }
      return (this.subscription.payment_profile.payment_type === "credit_card" || this.subscription.payment_profile.payment_type === "card") && this.subscription.payment_profile.masked_card_number != '';
    },

    cardValid () {
      let valid = this.cardExists;
      if (valid) {
        valid = this.cardExpiryDays >= 0;
      }
      return valid;
    },
    numberOfUsers () {
      if (this.subscription.stripe_id) {
        return this.subscription.number_of_users;
      }
      return this.subscription.product.number_of_users;
    },
    // gets days until card expires. Used to inform the customer to change card on the subscription
    cardExpiryDays () {
      const expiry = new Date(this.subscription.payment_profile.expiration_year, parseInt(this.subscription.payment_profile.expiration_month), 1);
      const msToExpiry = expiry.getTime() - new Date().getTime();
      return Math.round(msToExpiry / (1000 * 60 * 60 * 24));
    },

    //show expiring card message based on card expiry month
    cardExpiring () {
      return this.userPerms.billing_management && this.cardExpiryDays <= 31;
    },

    subscriptionTrialing () {
      return this.filterSet.trialing &&
        this.subscription.subscription_state == 'trialing';
    },

    subscriptionTrialEnded () {
      return this.filterSet.trialEnded &&
        this.subscription.subscription_state == 'trial_ended';
    },

    subscriptionPaused () {
      return this.filterSet.paused &&
        this.subscription.subscription_state == 'paused';
    },

    subscriptionPastDue () {
      return this.filterSet.pastDue &&
        this.subscription.subscription_state == 'past_due';
    },

    subscriptionOnHold () {
      return this.filterSet.onHold &&
        this.subscription.subscription_state == 'on_hold';
    },

    subscriptionCancelled () {
      return this.filterSet.cancelled &&
        this.subscription.subscription_state == 'canceled';
    },

    subscriptionFull () {
      if (!this.filterSet.subscriptionFull) return false;
      let seatsRemaining = parseInt(this.subscription.dictation_seats) - parseInt(this.subscription.number_of_dictation_users);
      return seatsRemaining === 0;
    },
  },
  methods: {
    toggleSubscriptionModal () {
      this.$emit("toggleSubscriptionModalEvent");
    },

    toggleShippingModal () {
      this.$emit("toggleShippingModalEvent");
    },

    toggleBillingModal () {
      this.$emit("toggleBillingModalEvent");
    },
    reactivateSubscription () {
      this.$emit("reactivateSubscription");
    },
    setRemindLater () {
      this.remindLater = true;
      localStorage.setItem("remindLater", true);
    }
  }
};
</script>
<style scoped>
.prompt-banner {
  @apply px-8;
  @apply justify-center;
  @apply bg-pastel-green-lighter;
  @apply border;
  @apply border-rare2-darker;
  @apply mb-4;
  /* box-shadow: 4px 4px 0 2px rgba(82, 186, 226, 0.25); */
}

.error-banner {
  @apply px-8;
  @apply justify-center;
  @apply bg-pastel-red-lighter;
  @apply border;
  @apply border-error;
  @apply mb-4;
  /* box-shadow: 4px 4px 0 2px rgba(82, 186, 226, 0.25); */
}

@media (min-width: 640px) {
  .error-banner {
    @apply px-6;
  }

  .prompt-banner {
    @apply px-6;
  }
}
</style>