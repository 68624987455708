<template>
  <BladeHeader>
    <div class="flex text-neutral-alt justify-center lg:justify-left mx-auto lg:mx-0 font-extrabold text-base lg:pl-6 my-auto">
      <BubbleToggle
        :selected="bubbleSelected"
        :selections="['Note Inbox', 'Archived']"
        setWidth="8rem"
        @selection-made="(val) => { changeNotesList(val) }"
      />
    </div>
  </BladeHeader>
  <div class="notes-blade w-full flex flex-col mx-auto pl-2 lg:pt-6 lg:pl-6">
    <!-- draft collapsable section-->
    <div class="pr-2 xl:pr-6">

      <transition name="fade-pull" appear>
        <div v-if="draftNotes.totalCount !== 0" class="header-transition-duration replace-fade-pull flex flex-col w-full bg-pastel-purple-light hover:bg-rare-lightest border border-rare-light rounded-xl ">
          <div id="draft-list-collapsable" class="clickable-element p-2.5 flex flex-grow items-center"
               role="button" @click="showDrafts = !showDrafts">
            <!-- draft count -->
            <div :class="['count-icon flex flex-col justify-center text-center bg-rare rounded text-white font-extrabold text-sm transition-colors',
                          draftNotes.draftNotif ? 'bg-rare-dark' : '']">
              {{ draftNotes.totalCount }}
            </div>
            <div class="relative">
              <Transition name="fade-pull">
                <span v-if="draftNotes.draftNotif" class="replace-fade-pull duration-150 pl-3.5 pr-2 text-rare-dark text-sm font-roboto whitespace-nowrap">
                  A new draft is being recorded!
                </span>
                <span v-else class="replace-fade-pull block duration-150 pl-3.5 pr-2 text-neutral text-sm font-roboto">
                  Drafts waiting to be submitted.
                </span>
              </Transition>
            </div>
            <button id="submit-all-drafts" class="as-anchor font-bold leading-4 whitespace-nowrap" :disabled="submittingDrafts" @click.stop="submitDrafts">
              Submit All
            </button>
            <button class="ml-auto flex items-center" @click.stop="showDrafts = !showDrafts">
              <span :class="['material-icons  text-rare transform transition-transform',
                             showDrafts ? 'flip-x' : '']">
                keyboard_arrow_down</span>
            </button>
          </div>

          <!-- listing draft Notes -->
          <Transition name="expand"
                      @before-enter="setEnterLeaveHeight"
                      @before-leave="setEnterLeaveHeight"
                      @enter="setTransitionHeight">
            <div v-show="showDrafts" class="transition-all overflow-hidden">
              <div class="max-h-[138px] overflow-y-auto">
                <RouterLink
                  v-for="(note,index) in draftNotes.notes" :id="`draft-note-${index}`" :key="note.id"
                  :to="`/notes/${note.id}`" class="note-button round-more pl-3 py-3 flex font-roboto invisible-anchor items-center
                hover:bg-gray-100 duration-150 transition-colors"
                  @click="trackNoteNav(note)">
                  <span class="whitespace-nowrap font-bold pl-2 pr-4 text-xs">{{ note.displayDate.time }}</span>
                  <!-- editors note: holy heck actually working ellipsis overflow? I put way too many thought cycles into these 2 lines of HTML/CSS -->
                  <div class="table table-fixed w-full pr-2">
                    <span class="table-cell whitespace-nowrap overflow-ellipsis overflow-hidden text-sm font-roboto font-semibold">
                      {{ note.transcript_preview ? removeMdCharacters(note.transcript_preview) : note.title ? note.title : 'untitled note' }}
                    </span>
                  </div>
                </RouterLink>
              </div>
            </div>
          </Transition>

        </div>
        <!-- <div v-else-if="loadingNotes" class="replace-fade-pull h-[52px]"></div> -->
      </transition>
    </div>

    <!-- actions filters search -->

    <div class="relative  flex justify-between flex-grow pr-2 lg:pr-6 mt-5 mb-6 h-[34px] max-h-[34px]">
      <ActionMenu
        :checkVal="allSelected"
        @check-click="selectAll(!allSelected)">
        <div class="flex">
          <button class="whitespace-nowrap font-medium p-3 text-neutral-alt hover:bg-pastel-blue-light hover:bg-opacity-25"
                  @click="archiveSelection(bubbleSelected === 0)">
            {{ bubbleSelected === 0 ? 'Archive' : 'Unarchive' }} Selection
          </button>
        </div>
      </ActionMenu>

      <!-- filter and search block-->
      <div :class="['filter-wrapper', showSearch ? 'expand-search' : '']">
        <BaseIconDropdown
          id="note-list-filter-dropdown"
          class="text-xl rounded-xl border h-[34px] w-[112px] ml-2.5 bg-white border-[#9BACB2] hover:bg-gray-100 transition-colors"
          menuText="Filters"
          icon="filter_list"
          no-border
          menuX="right-0"
          menuY="top-8"
          :notifCount="filterCount"
          square
        >

          <div class="px-2 w-60">
            <div class="flex justify-between">
              <span class="font-bold text-xl text-black my-2">Filters</span>
              <button class="as-anchor text-base font-bold my-2" @click="clearHeaderFilters">
                Clear Filters
              </button>
            </div>
            <BaseDatepicker
              id="note-date-range-input"
              class="pb-2"
              placeholder="Date Range"
              :date="dateFilter"
              @update:date="queryDate" />

            <!-- v-if="authors.length > 1" -->
            <BaseMultiSelect
              id="note-author-filter-multiselect"
              class="pb-2"
              :value="authorsMultiselect"
              placeholderText="Recorded By"
              fullMenu
              showCount
              ellipsis
              showSearch
              @update:value="queryUser"
            />

            <BaseMultiSelect
              id="note-status-filter-multiselect"
              class="pb-2"
              :value="statusMultiselect"
              placeholderText="Status"
              fullMenu
              showCount
              ellipsis
              showSearch
              @update:value="queryStatus"
            />
          </div>

        </BaseIconDropdown>

        <div class="blade-search flex ml-2.5 border border-[#9BACB2] bg-white rounded-xl">
          <div :class="['search-wrapper overflow-hidden']">
            <input id="note-search-input" v-model="searchText" type="text"
                   class="text-search-input rounded-xl h-full w-full flex flex-grow" />
          </div>
          <button :class="['my-auto px-2']" @click="toggleSearch">
            <span :class="'material-icons text-[#9BACB2] text-lg'"> search</span>
          </button>
        </div>
      </div>
    </div>
    <!-- <button @click="spliceIntoRecent"> test</button> -->
    <!-- notes list -->
    <div id="blade-notes-list" class="overflow-y-scroll flex-grow overflow-x-hidden pr-2">

      <div v-if="!loadingNotes" class="hidden last:flex flex-col rounded-md p-3 text-center
                    bg-white border border-rare font-bold">
        <span class="text-base pb-2">Try out Talkatoo Notes!</span> Record patient notes on your phone and they will be automatically processed as a SOAP note.
      </div>
      <TransitionGroup name="list" appear>
        <div v-for="(day, index) in notesList " :key="day.key"
             class="flex-col transition-all last:mb-16">
          <div class="sticky top-0 z-10 bg-pastel-purple-lighter">

            <div class="pb-2 text-sm font-roboto font-bold text-neutral">
              {{ day.heading }}
            </div>
          </div>
          <div class="note-group mb-4">
            <TransitionGroup name="list" appear>
              <RouterLink
                v-for="(note, noteIndex) in day.notes" :id="`note-link-${index}-${noteIndex}`" :key="note.id"
                :to="`/notes/${note.id}`"
                :class="['note-button pl-3 py-3 flex font-roboto invisible-anchor items-center',
                         'hover:bg-gray-100 duration-150 transition-colors',
                         note.state == 3 || note.state == 4 ? 'bg-pastel-green-light' : 'bg-white',
                         note.state == 5 ? 'bg-pastel-yellow-light opacity-50 pr-16' : 'bg-white',
                         note.changeNotif || note.state == 5 ? 'pr-16' : '']
                "
                @click="trackNoteNav(note)">
                <div class="block">
                  <input
                    :checked="note.selected"
                    type="checkbox"
                    class="tk-check"
                    :value="note.selected"
                    @click.stop="selectNote(note)" />
                </div>

                <span class="block whitespace-nowrap font-bold pl-2 pr-4 text-sm min-w-[4.5rem]">{{ note.displayDate.time }} </span>
                <!-- editors note: holy heck actually working ellipsis overflow? I put way too many thought cycles into these 2 lines of HTML/CSS -->
                <div class="table table-fixed w-full pr-2">
                  <span :class="['table-cell w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-sm font-roboto font-semibold ',
                                 note.state != 1 ? 'text-neutral' : 'text-neutral-darker']
                  ">
                    <template v-if="note.state == 5">
                      Generating Title...
                    </template>
                    <template v-else>
                      <span v-if="note.assistant_generated" class="text-neutral-alt font-bold">Assistant Generated: </span>
                      {{ note.title ? note.title : note.transcript_preview ? removeMdCharacters(note.transcript_preview) : 'untitled note' }}
                    </template>
                  </span>
                  <BaseLoading v-if="note.state == 5" class="table-cell absolute top-0 right-0 transform scale-50 -translate-y-1/4" />
                  <div v-else-if="note.changeNotif" class="table-cell absolute top-1/2 right-2 transform -translate-y-1/2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
                      <path d="M10.5 3.77L17.2095 14H3.7905L10.5 3.77ZM10.5 0L0 16H21L10.5 0Z" fill="#52BAE2" />
                    </svg>
                  </div>
                </div>
              </RouterLink>
            </TransitionGroup>
          </div>
        </div>
      </TransitionGroup>
    </div>

  </div>

</template>
<script>
const NotesService = require("Services/NotesService");
import MixpanelService from "@/services/MixpanelService";
import BladeHeader from "./BladeHeader.vue";
import BubbleToggle from "@/components/ui/BubbleToggle.vue";
import { QueryManager, Operators, SupportedTypes, Sorting, NoteViews } from "Modules/NotesQueryManager.js";
import BaseLoading from "@/components/ui/BaseLoading.vue";
import BaseIconDropdown from "@/components/ui/BaseIconDropdown.vue";
import BaseDatepicker from "@/components/ui/BaseDatepicker.vue";
import BaseMultiSelect from "@/components/ui/BaseMultiSelect.vue";
import NoteStatesMap from "@/modules/NoteStatesMap";
import ActionMenu from "Components/ui/ActionMenu";
import { golden_retriever } from "@/tests/mock/seeds/ProductSeeds";

export default {
  name: "NotesBlade",
  components: {
    BladeHeader, BubbleToggle, BaseLoading, BaseIconDropdown,
    BaseDatepicker, BaseMultiSelect, ActionMenu
  },
  props: {},
  data () {
    const currUserId = this.$store.getters.getUserId;
    let userConfig = localStorage.getItem(`NotesBlade:${currUserId}-UserConfig`);
    if (!userConfig) userConfig = { cachedFilters: {} };
    else userConfig = JSON.parse(userConfig);
    if (userConfig?.cachedFilters?.dateFilter && userConfig.cachedFilters.dateFilter.length === 2) {
      userConfig.cachedFilters.dateFilter[0] = new Date(userConfig.cachedFilters.dateFilter[0]);
      userConfig.cachedFilters.dateFilter[1] = new Date(userConfig.cachedFilters.dateFilter[1]);
    }

    return {
      currUserId,
      userConfig,
      //0 == active 1 == archived
      bubbleSelected: 0,

      loadingNotes: true,

      showDrafts: false,
      submittingDrafts: false,
      showSearch: false,
      notesList: [],
      recentNotes: {
        queryManager: new QueryManager({ paged: true, pageSize: 30, asTable: true, noteView: NoteViews.All }),
        defaultFilters: [{ field: 'state', value: [NoteStatesMap.indexOf("Draft")], op: Operators.NE, type: SupportedTypes.INT },
        { field: 'archived', value: [false], op: Operators.EQ, type: SupportedTypes.BOOL },],
        totalCount: 0,
        page: 1,
        list: [
          // { heading: "Today", notes: [] }, { heading: "Yesterday", notes: [] } // sample data
        ],
        notes: [],
      },
      archivedNotes: {
        queryManager: new QueryManager({ paged: true, pageSize: 30, asTable: true, noteView: NoteViews.ARCHIVED }),
        defaultFilters: [],
        totalCount: 0,
        page: 1,
        list: [
          // { heading: "Today", notes: [] }, { heading: "Yesterday", notes: [] } // sample data
        ],
        notes: [],
      },
      draftNotes: {
        queryManager: new QueryManager({ paged: false, asTable: true, noteView: NoteViews.ACTIVE }),
        defaultFilters: [{ field: 'state', value: [NoteStatesMap.indexOf("Draft")], op: Operators.EQ, type: SupportedTypes.INT }],
        totalCount: 0,
        page: 1,
        // list: [
        //   // { heading: "Today", notes: [] }, { heading: "Yesterday", notes: [] } // sample data
        // ],
        notes: [],
        draftNotif: false,
      },

      dateFilter: userConfig?.cachedFilters?.dateFilter ?? [],
      authors: [],
      authorsMultiselect: {},
      authorsIdMap: {},
      statusMultiselect: {},
      allSelected: false,

      searchText: '',

      activeRequest: 0,

      wsIds: [],
    };
  },
  mounted () {
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteStateEvent(this.noteStateEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteIssueEvent(this.noteIssueEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onInternalEvent(this.internalEventHandler));

  },
  created () {
    this.loadingNotes = true;

    let cachedFilters = undefined; // todo add cached filters here
    let authorProm = NotesService.GetAuthorList()
      .then((resp) => {
        let i = 0;
        const names = resp.data.authors.map(x => `${x.first_name} ${x.last_name}`);
        this.authors = resp.data.authors.map((x, index, self) => {
          // add integer to names when there are duplicates. Not great but its something
          let dup = names.indexOf(`${x.first_name} ${x.last_name}`) !== index;
          return {
            firstName: x.first_name, lastName: x.last_name,
            name: `${x.first_name} ${x.last_name}${dup ? ` ${(++i)}` : ''}`,
            userId: parseInt(x.user_id)
          };
        });
        let filter = this.userConfig?.cachedFilters?.userFilter;
        this.authorsMultiselect = this.authors
          .reduce((prop, curr) => {
            if (filter && filter[curr.name]) {
              prop[curr.name] = true;
            } else {
              prop[curr.name] = false;
            }
            return prop;
          }, {}),
          this.authorsIdMap = this.authors.reduce((prop, x) => {
            prop[x.name] = x.userId;
            return prop;
          }, {});
      });

    // handle notes once author list is resolved
    authorProm.then(() => {
      let noteProm;
      if (this.bubbleSelected === 0) noteProm = this.getRecentNotes();
      else noteProm = this.getArchivedNotes();
      this.getDraftNotes();

      noteProm
        .then((resps) => {
          if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          else this.notesList = this.archivedNotes.list;
          this.loadingNotes = false;
          this.initInfiniteScroll();
        });
    });


    this.statusMultiselect = this.userConfig.cachedFilters.statusFilter ?? {
      // "Draft": false,
      "Awaiting Review": false,
      "In Review": false,
      "Completed": false,
      // "In PMS": false,
      // "Processing": false,
      // "Needs Attention": false,
    };
  },
  beforeUnmount () {
    this.$store.getters.getWebsocketEventHandler.removeEvents(this.wsIds);
  },
  watch: {
    searchText (newVal, oldVal) {
      this.getFilteredNotes();
    },
  },
  computed: {
    parsedFilters () {
      let queryFilters = [];
      let filters = this.userConfig.cachedFilters;
      if (!filters) return [];
      if (filters.dateFilter && filters.dateFilter.length == 2) {
        const start = new Date(filters.dateFilter[0]).setHours(0, 0, 0, 0) / 1000;
        const end = new Date(filters.dateFilter[1].getTime()).setHours(23, 59, 59, 999) / 1000;
        queryFilters.push({ field: 'date_filter', value: [start, end], op: Operators.BT, type: SupportedTypes.INT });
      }
      if (filters.userFilter) {
        let userIds = [];
        Object.keys(filters.userFilter).forEach(key => {
          if (filters.userFilter[key]) {
            userIds.push(this.authorsIdMap[key]);
          }
        });
        if (userIds.length > 0) {
          queryFilters.push({ field: 'user_id', value: userIds, op: Operators.IN, type: SupportedTypes.INT });
        }
      }
      if (filters.statusFilter) {
        let states = [];
        let na = false;
        NoteStatesMap.forEach((state, index) => {
          if (filters.statusFilter[state] && state == "Needs Attention") { na = true; }
          else if (filters.statusFilter[state]) states.push(index);
        });
        if (filters.statusFilter["Completed"]) {
          states.push(3, 4);
        }
        if (states.length > 0) {
          queryFilters.push({ field: 'state', value: states, op: Operators.IN, type: SupportedTypes.INT });
        } if (na) {
          queryFilters.push({ field: 'issue_count', value: [0], op: Operators.NE, type: SupportedTypes.INT });
        }
      }
      return queryFilters;
    },
    filterCount () {
      let count = 0;
      if (this.userConfig.cachedFilters.dateFilter && this.userConfig.cachedFilters.dateFilter.length === 2) count++;
      if (this.userConfig.cachedFilters.userFilter) {
        Object.keys(this.userConfig.cachedFilters.userFilter).forEach(key => { if (this.userConfig.cachedFilters.userFilter[key]) count++; });
      }
      if (this.userConfig.cachedFilters.statusFilter) {
        Object.keys(this.userConfig.cachedFilters.statusFilter).forEach(key => { if (this.userConfig.cachedFilters.statusFilter[key]) count++; });
      }
      return count;
    }
  },
  methods: {
    getRecentNotes () {
      return this.recentNotes.queryManager.getNotesWith({
        filters: [
          ...this.recentNotes.defaultFilters,
          ...this.parsedFilters
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        noteView: NoteViews.ALL,
        page: 1,
      })
        .then(resp => {
          this.recentNotes.notes = resp.data.notes;
          this.recentNotes.list = this.parseNotesToPreview(resp.data.notes);
          this.recentNotes.totalCount = resp.data.total_count;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          this.loadingNotes = false;
        });

    },
    getArchivedNotes () {
      return this.archivedNotes.queryManager.getNotesWith({
        filters: [
          ...this.archivedNotes.defaultFilters,
          ...this.parsedFilters
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        noteView: NoteViews.ARCHIVED,
        page: 1,
      })
        .then(resp => {
          this.archivedNotes.notes = resp.data.notes;
          this.archivedNotes.list = this.parseNotesToPreview(resp.data.notes);
          this.archivedNotes.totalCount = resp.data.total_count;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          this.loadingNotes = false;
        });

    },
    getDraftNotes () {
      // just get drafts. use a new query manager just to format the request
      this.draftNotes.queryManager.getNotesWith({
        filters: [
          { field: 'state', value: [NoteStatesMap.indexOf("Draft")], op: Operators.EQ, type: SupportedTypes.INT }
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        noteView: NoteViews.ACTIVE,
        page: 1,
      })
        .then(resp => {
          // this.draftNotes.list = resp.data.notes;
          this.draftNotes.notes = resp.data.notes;
          this.draftNotes.totalCount = resp.data.total_count;
          this.parseDraftNotesToPreview();
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          this.loadingNotes = false;
        });
    },
    getNextNotePage () {
      let listRef;

      if (this.bubbleSelected === 0) listRef = this.recentNotes;
      else listRef = this.archivedNotes;

      if (listRef.notes.length >= listRef.totalCount) return;
      this.loadingNotes = true;
      // this.loading = true;
      listRef.page++;
      listRef.queryManager.getNotesWith({
        filters: [
          ...listRef.defaultFilters,
          ...this.parsedFilters
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        page: listRef.page,
      }).then(resp => {
        listRef.notes.push(...resp.data.notes);
        listRef.list = this.parseNotesToPreview(listRef.notes);
        listRef.totalCount = resp.data.total_count;
        this.notesList = listRef.list;
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.loadingNotes = false;
      });
    },
    getFilteredNotes () {
      this.allSelected = false;

      this.activeRequest += 1;
      const currRequest = this.activeRequest;

      let listRef;
      if (this.bubbleSelected === 0) listRef = this.recentNotes;
      else listRef = this.archivedNotes;

      this.loadingNotes = true;
      // this.loading = true;
      listRef.page = 1;
      listRef.queryManager.getNotesWith({
        filters: [
          ...listRef.defaultFilters,
          ...this.parsedFilters
        ],
        textSearch: this.searchText,
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        page: 1,
      }).then(resp => {
        if (this.activeRequest !== currRequest) return;
        listRef.list = this.parseNotesToPreview(resp.data.notes);
        listRef.notes = resp.data.notes;
        listRef.totalCount = resp.data.total_count;
        this.notesList = listRef.list;
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.loadingNotes = false;
      });
    },
    initInfiniteScroll () {
      const notesList = document.getElementById(`blade-notes-list`);
      if (notesList) {
        notesList.addEventListener("scroll", (event) => { this.scrollHandler(event); });
      } else {
        console.warn("Failed to init infinite scroll");
        window.setTimeout(this.initInfiniteScroll, 1000);
      }
    },
    scrollHandler (event) {
      const cont = event.target;
      const contHeight = event.target.getBoundingClientRect().height;
      const scrollTriggerPadding = 64;
      if (cont.scrollHeight - (cont.scrollTop + contHeight + scrollTriggerPadding) <= 0) {
        if (!this.loading && this.recentNotes.list.length !== this.recentNotes.totalCount) {
          this.getNextNotePage();
        }
      }
    },
    // 0 == active 1 == archived
    changeNotesList (view) {
      this.bubbleSelected = view;
      this.loadingNotes = true;
      this.notesList = [];
      if (this.showSearch) this.toggleSearch();
      this.clearHeaderFilters();
      this.selectAll(false);

      let noteProm;
      if (this.bubbleSelected === 0) noteProm = this.getRecentNotes();
      else noteProm = this.getArchivedNotes();
      this.getDraftNotes();

      Promise.all([noteProm])
        .then((resps) => {
          if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          else this.notesList = this.archivedNotes.list;
          this.loadingNotes = false;
        });
    },
    // temporary for show
    parseNotesToPreview (notes) {
      let map = {};
      notes.forEach((note) => {
        let ts = 0;
        if (note.state == 0 || note.state == 5) {
          ts = note.created_at;
        } else if (note.published_at == 0)
          ts = note.last_edited;
        else {
          ts = note.published_at;
        }

        note.sortDate = ts;
        // ts in ms at midnight
        note.displayDate = {
          date: Intl.DateTimeFormat(undefined, { month: "short", day: "numeric", year: "numeric", }).format(ts * 1000),
          time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", }).format(ts * 1000),
        };
        note.mapDate = new Date(ts * 1000).setHours(0, 0, 0, 0);
      });
      if (this.isUserScribe) {
        notes.sort((a, b) => {
          if (a.needs_attention) {
            return -1;
          }
        });
      }
      notes.sort((a, b) => {
        return a.sortDate === b.sortDate ? 0 :
          a.sortDate < b.sortDate ? 1 : -1;
      });
      if (!this.isUserScribe) {
        notes.sort((a, b) => {
          if (a.needs_attention) {
            return -1;
          }
        });
      }
      let now = new Date();
      notes.forEach((note) => {
        let label = "";
        if (note.needs_attention && !this.isUserScribe) {
          label = 'Needs Attention';
        } else {
          let d = new Date(note.mapDate);
          if (d.getFullYear() === now.getFullYear() &&
            d.getMonth() === now.getMonth()) {
            if (d.getDate() === now.getDate()) {
              label = "Today";
            } else if (d.getDate() === now.getDate() - 1) {
              label = "Yesterday";
            } else {
              label = new Date(note.mapDate).toString().slice(0, 'Fri Jan 01 1970'.length);
            }
          } else {
            label = new Date(note.mapDate).toString().slice(0, 'Fri Jan 01 1970'.length);
          }
        }
        if (!map[label]) {
          map[label] = [];
        }
        map[label].push(note);
      });

      let list = [];
      Object.keys(map).forEach((key) => {
        list.push({ heading: key === 'Needs Attention' ? key : key.slice(0, 'Fri Jan 01'.length), key: key, notes: map[key] });
      });
      return list;
    },
    parseDraftNotesToPreview () {

      this.draftNotes.notes.forEach((note) => {
        let ts = 0;
        if (note.state == 0 || note.state == 5) {
          ts = note.created_at;
        } else if (note.published_at == 0)
          ts = note.last_edited;
        else {
          ts = note.published_at;
        }
        note.sortDate = ts;
        // ts in ms at midnight
        note.displayDate = {
          date: Intl.DateTimeFormat(undefined, { month: "short", day: "numeric", year: "numeric", }).format(ts * 1000),
          time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", }).format(ts * 1000),
        };
        note.mapDate = new Date(ts * 1000).setHours(0, 0, 0, 0);
      });
    },
    archiveSelection (archive) {
      let cancelSplice = false;
      let selectedNotes = this.notesList.reduce((list, group) => {
        list.push(...group.notes);
        return list;
      }, []).filter(note => note.selected);

      let proms = [];
      selectedNotes.forEach(note => {
        note.selected = false;
        this.$store.getters.getWebsocketEventHandler.sendInternalUpdateEvent({
          type: "Note:ArchiveChange",
          noteId: note.id,
          archived: archive,
        });
        // TODO: archive the notes based on bubble selection
        let archived = NotesService.ArchiveNote(note.id, archive)
          .then(resp => {
            note = resp.data;
          })
          .catch((e) => {
            console.log(e);
            cancelSplice = true;
            this.$toast.error({ message: "Issue with submitting note for processing! make sure all audio is uploaded." });

          });
        proms.push(archived);
      });

      let removeFrom, addTo;

      if (archive) {
        removeFrom = this.recentNotes;
        addTo = this.archivedNotes;
      } else {
        removeFrom = this.archivedNotes;
        addTo = this.recentNotes;
      }

      Promise.all(proms).then(() => {
        if (cancelSplice) return;
        selectedNotes.forEach(note => {
          let i = removeFrom.notes.findIndex(x => x.id == note.id);
          if (i !== -1) {
            removeFrom.notes.splice(i, 1);
          }
          else {
            console.log('failed to find note');
          }
          addTo.notes.push(note);
        });
        removeFrom.list = this.parseNotesToPreview(removeFrom.notes);
        addTo.list = this.parseNotesToPreview(addTo.notes);
        if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
        else this.notesList = this.archivedNotes.list;
        this.selectAll(false);
      });

    },
    selectNote (note) {
      note.selected = !note.selected;
      if (note.selected) {
        this.allSelected = true;
      } else {
        let listRef = undefined;
        if (this.bubbleSelected === 0) listRef = this.recentNotes;
        else listRef = this.archivedNotes;
        if (listRef.notes.some(x => x.selected)) this.allSelected = true;
        else this.allSelected = false;
      }

    },
    selectAll (val) {
      this.allSelected = val;
      this.notesList.forEach(group => group.notes.forEach(note => note.selected = val));
    },
    submitDrafts () {
      this.submittingDrafts = true;
      let proms = [];
      let cancelSplice = false;
      this.draftNotes.notes.forEach(note => {
        let closed = NotesService.CloseNote(note.id)
          .then(resp => {
            note = resp.data;
          })
          .catch((e) => {
            console.log(e);
            cancelSplice = true;
            this.$toast.error({ message: "Issue with submitting note for processing! make sure all audio is uploaded." });

          });
        proms.push(closed);
      });
      Promise.all(proms).then(() => {
        if (cancelSplice) return;
        this.draftNotes.totalCount = 0;
        this.draftNotes.notes.forEach(note => {
          note.changeNotif = true;
          note.state = NoteStatesMap.indexOf("Processing");
          this.recentNotes.notes.splice(0, 0, note);
          this.recentNotes.totalCount++;
        });
        this.recentNotes.list = this.parseNotesToPreview(this.recentNotes.notes);
        if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
        this.draftNotes.notes = [];
      }).finally(() => {
        this.submittingDrafts = false;
      });

    },
    toggleSearch () {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) this.searchText = '';
      if (this.showSearch) {
        setTimeout(() => { document.getElementById("note-search-input").focus(); }, 100);
      }
    },
    noteStateEventHandler (e) {
      // new Draft note
      if (e.oldState == 0 && e.oldState == e.newState) {
        this.draftNotes.queryManager.invalidateCache();
        // this.getDraftNotes();
        NotesService.GetNote(e.noteId).then((resp) => {
          this.draftNotes.notes.push(resp.data);
          this.parseDraftNotesToPreview();
          this.draftNotes.totalCount++;
          this.draftNotes.draftNotif = true;
          setTimeout(() => {
            this.draftNotes.draftNotif = false;
          }, 3000);
        });
      }
      // draft submitted for processing
      else if (e.oldState == 0 && e.newState == 5) {
        let timeout = 0;
        if (this.draftNotif) {
          timeout = 3000;
        }
        window.setTimeout(() => {
          this.draftNotes.queryManager.invalidateCache();
          this.recentNotes.queryManager.invalidateCache();

          let index = this.draftNotes.notes.findIndex(x => x.id == e.noteId);
          if (index !== -1) {
            let noteArr = this.draftNotes.notes.splice(index, 1);
            this.draftNotes.totalCount--;
            noteArr[0].changeNotif = true;
            noteArr[0].state = 5;
            noteArr.published_at = e.eventTs;
            // note not in the list of recent notes. draft => processing
            if (this.recentNotes.notes.findIndex(x => x.id == e.noteId) === -1) {
              this.recentNotes.notes.splice(0, 0, ...noteArr);
              this.recentNotes.totalCount++;
            }

            if (this.draftNotes.notes.length > 0) {
              this.draftNotes.draftNotif = true;
              setTimeout(() => {
                this.draftNotes.draftNotif = false;
              }, 3000);
            }
            this.draftNotes.list = this.parseNotesToPreview(this.draftNotes.notes);
            this.recentNotes.list = this.parseNotesToPreview(this.recentNotes.notes);
            if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          }
          this.draftNotes.draftNotif = true;
          setTimeout(() => {
            this.draftNotes.draftNotif = false;
          }, 3000);
        }, timeout);

      }
      //  processing finished
      else if (e.oldState == 5 && e.newState == 1) {
        let note = this.recentNotes.notes.find(x => x.id == e.noteId);
        if (note !== undefined) {
          NotesService.GetNote(e.noteId).then(resp => {
            note.title = resp.data.title;
            note.transcript_preview = resp.data.edited_transcript_string.substr(0, 200);
            note.state = 1;
            note.changeNotif = true;
            note.created_at = resp.data.created_at;
            note.last_edited = resp.data.last_edited;
            note.published_at = resp.data.published_at;
          });
          // we're pretty out of date if the note is missing from the notes list
        } else {
          this.recentNotes.queryManager.invalidateCache();
          this.draftNotes.queryManager.invalidateCache();
          this.getDraftNotes();
          this.getRecentNotes().then(() => {
            if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          });
        }
      }
      else if (e.newState == 3 || e.newState == 4) {
        for (let i = 0; i < this.notesList.length; i++) {
          let note = this.notesList[i].notes.find(x => x.id == e.noteId);
          if (note && note.state != e.newState) {
            note.state = e.newState;
            note.changeNotif = true;
            return;
          }
        }
      }
    },
    noteIssueEventHandler (e) {
    },
    internalEventHandler (e) {
      if (e.type === "Notes:TitleUpdate") {
        let listRef;
        if (this.bubbleSelected === 0) listRef = this.recentNotes;
        else listRef = this.archivedNotes;

        let note = listRef.notes.find(x => x.id == e.noteId);
        if (note) {
          note.title = e.newTitle;
        }
      } else if (e.type === "Note:ArchiveChange") {
        let listRef, hiddenRef;
        // if archived move from recent to archived. visversa
        if (e.archived) {
          listRef = this.recentNotes;
          hiddenRef = this.archivedNotes;
          this.archivedNotes.queryManager.invalidateCache();
        }
        else {
          listRef = this.archivedNotes;
          hiddenRef = this.recentNotes;
          this.recentNotes.queryManager.invalidateCache();
        }
        let index = listRef.notes.findIndex(x => x.id == e.noteId);
        if (index !== -1) {
          let note = listRef.notes.splice(index, 1)[0];
          note.archived = e.archived;
          listRef.list = this.parseNotesToPreview(listRef.notes);
          if (this.bubbleSelected === 0 && e.archived) this.notesList = listRef.list;
          else if (this.bubbleSelected === 1 && !e.archived) this.notesList = listRef.list;
          else {
            hiddenRef.notes.push(note);
            hiddenRef.list = this.parseNotesToPreview(hiddenRef.notes);
            this.notesList = hiddenRef.list;
          }
        } else {
          // no reference to the note in available lists, get it from server
          this.getFilteredNotes();
        }
      }
    },
    removeMdCharacters (str) {
      // find all unescaped * and remove them
      str = str.replaceAll(/\n{2}/g, '\n');
      // .replaceAll(/(?<!\\)\*+/g, '')
      for (let i = 1; i < str.length-1; i++) {
        if (str.charCodeAt(i) == 42 /* '*' */ && str.charCodeAt(i - 1) != 92/* \ */) {
          str = str.substring(0, i) + str.substring(i+1);
          i--;
        }
      }
      if (str.charCodeAt(0) == 42) str = str.substring(1);

      return str.replace(/\\([*_`{}\[\]()#+\-.!])/g, '$1').trim();
    },
    trackNoteNav (note) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:NoteNavigation", {
        subscription_id: subId,
        note_id: note.id,
        source: "dashboard",
      });
    },
    parseCachedFilters () {

    },
    clearHeaderFilters () {
      this.userConfig.cachedFilters = {};
      this.dateFilter = [];
      this.authorsMultiselect = this.authors
        .reduce((prop, curr) => {
          prop[curr.name] = false;
          return prop;
        }, {}),
        this.statusMultiselect = {
          // "Draft": false,
          "Awaiting Review": false,
          "In Review": false,
          "Completed": false,
          // "In PMS": false,
          // "Processing": false,
          // "Needs Attention": false,
        };
      this.saveUserConfig();
      this.getFilteredNotes();
    },
    queryDate (range) {
      this.dateFilter = range;
      this.userConfig.cachedFilters.dateFilter = range;
      this.saveUserConfig();
      this.getFilteredNotes();

    },
    queryUser (filter) {
      this.userConfig.cachedFilters.userFilter = filter;
      this.saveUserConfig();
      this.getFilteredNotes();

    },
    queryStatus (filter) {
      this.userConfig.cachedFilters.statusFilter = filter;
      this.saveUserConfig();
      this.getFilteredNotes();
    },
    saveUserConfig () {
      localStorage.setItem(`NotesBlade:${this.currUserId}-UserConfig`, JSON.stringify(this.userConfig));
    },
    clearCustomTableFilters () {
      this.userConfig.cachedFilters = {};
      this.saveUserConfig();
      // localStorage.removeItem("Notes:CustomTableFilters");
      this.setCustomTableFilter();
    },
    // this height transition will only work in specific cases. It doesn't handle nesting elements
    // before initial render
    setEnterLeaveHeight (el) {
      el.style.maxHeight = "0px";
    },
    // called one frame after the element is inserted.
    setTransitionHeight (el, done) {
      this.$nextTick(() => {

        let height = 0;
        for (var i = 0; i < el.childElementCount; i++) {
          height = Math.max(el.children[i].clientHeight, height);
        }
        height += el.getBoundingClientRect().height; // adds padding
        el.style.maxHeight = `${height}px`;
        setTimeout(done, 150);
      });
    },
  }
};
</script>
<style scoped>
.notes-blade {
  max-height: calc(100vh - var(--header-h) - 56px);
  @apply flex-grow;
}

@media (min-width: 1024px) {
  .notes-blade {
    max-height: calc(100vh - var(--header-h));
  }
}

.count-icon {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}

.note-group {
  @apply w-full;
  @apply border;
  @apply border-main-alt;
  @apply bg-white;
  @apply rounded;
}


.note-button:first-child {
  @apply rounded-t-lg;
}

.note-button:last-child {
  @apply rounded-b-lg;
}

.note-button {
  position: relative;
  @apply text-black;
  @apply font-bold;
}

.note-button:hover {
  @apply text-black;
  @apply bg-neutral-100;
  text-decoration: none;
}

.note-button:not(:last-child) {
  @apply border-b;
  @apply border-neutral-lighter
}

.note-button.router-link-active {
  @apply bg-pastel-blue-lighter;
  @apply bg-opacity-50;
  @apply transition-colors;
}

.note-button {
  max-height: 45px;
}

.note-button.list-leave-to {
  max-height: 0px;
  padding: 0px;
  overflow-y: hidden;
}

.filter-wrapper {
  display: flex;
  @apply justify-end;
  @apply flex-shrink;
}

/* some controls over the expandable search bar */
.search-wrapper {
  display: grid;
  grid-template-columns: 0fr;
  transition: grid-template-columns 300ms ease-in-out;
}

.filter-wrapper.expand-search .search-wrapper {
  grid-template-columns: 1fr;
}

.blade-search {
  display: flex;
  @apply justify-end;
}

.blade-search button {
  height: 100%;
  @apply rounded-xl;
  @apply hover:bg-gray-100;
  transition-property: background-color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.blade-search input {
  @apply pl-0;
  min-width: 0px;
  flex-grow: 1;
  transition-property: padding;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.filter-wrapper.expand-search .blade-search input {
  @apply pl-2;
  @apply w-auto;
}

.note-button.round-more:last-child {
  @apply rounded-b-xl;
}


.header-transition-duration {
  transition-duration: 300ms;
}
</style>