<template>
  <div id="notes"
       class="pb-4 flex flex-col bg-white min-h-[60vh] rounded-t-md lg:rounded-t-0">
    <div class="heading flex-row mb-5">
      <div class="flex flex-grow justify-between 2xl:max-w-[1240px] 3xl:max-w-[1240px] 4xl:max-w-[1403px]">
        <div class="my-auto text-3xl font-bold">
          Users
        </div>
        <div class="flex flex-col-reverse lg:flex-row justify-center lg:h-full text-sm font-bold ">
          <button v-if="loading || invitesRemaining > 0 || !userPerms.billing_management"
                  class="btn-heading my-auto w-full"
                  :disabled="(loading || invitesRemaining <= 0) ?? undefined"
                  @click="toggleInviteModal">
            Invite New User
          </button>
          <button v-else
                  class="btn-heading-prompt my-auto w-full font-bold"
                  @click="toggleSubscriptionModal">
            Upgrade Now!
          </button>
        </div>
      </div>

    </div>
    <div class="flex flex-col w-full 2xl:max-w-[1280px] 4xl:max-w-[1442px]">
      <div class="body-container">

        <HeaderBanner :loading="loading" :subscription="subscription"
                      @toggleSubscriptionModal="toggleSubscriptionModal"
                      @toggleBillingModalEvent="toggleBillingModal" />
      </div>
      <!-- simple user count as progress bars -->
      <div class="body-container mb-2">
        <div class="container-gray flex w-full py-4 xl:py-0 xl:min-h-[6.75rem] border-">
          <BaseLoading v-if="loading" class="m-auto" />
          <template v-else-if="!loading">
            <div class="flex w-full xl:pt-9">
              <div class="flex xl:flex-col flex-grow my-auto px-5">
                <div class="h-7 rounded-md bg-gray-200 w-full xl:mb-2 mr-2 xl:mr-0">
                  <div class="h-full bg-main-light rounded-md rounded-l transition-[width] ease-in-out"
                       :style="`width:${dictationSeatFillPercent}%;`">
                  </div>
                </div>
                <div class="flex justify-center my-auto xl:pb-4 ">
                  <span class="text-main-alt font-bold font-roboto text-sm whitespace-nowrap">
                    {{ dictationUsers.length }} of {{ numberOfUsers }} dictation {{ numberOfUsers == 1 ? 'seat' : 'seats' }} used
                  </span>
                </div>
              </div>
            </div>
            <div v-if="subHasVerifiedSeats" class="hidden xl:flex w-full pt-9 border-l border-[#E7EBF2] ">
              <div class="flex-grow my-auto px-5">
                <div class="h-7 rounded-md bg-gray-200 w-full mb-2">
                  <div class="h-full w-1/2 bg-rare-light rounded-md rounded-l transition-[width] ease-in-out"
                       :style="`width:${verifiedSeatFillPercent}%;`">
                  </div>
                </div>
                <div class="flex justify-center">
                  <span class="text-rare-alt font-bold font-roboto text-sm pb-4">
                    {{ verifiedUsers.length }} of {{ verifiedSeatsCount }} Verified by Talkatoo users
                  </span>
                </div>
              </div>
            </div>
          </template>

        </div>
      </div>
      <!-- Table element -->
      <div class="body-container">
        <div class="container-gray">
          <UserTable
            title="ALL USERS"
            :colDef="colDef"
            :data="tableData"
            :showLoading="loading"
            :paginationSeed="{ page: 1, numberOnPage: 15 }"
            :callToAction="callToAction"
            searchPlaceholder="Search name, email or permissions"
            @action-item-click="actionItemClick"
            @toggle-select-all="toggleAllSelection"
            @toggle-select="rowSelect"
            @submit-dropdown="updateSinglePermissions"
            @row-action-click="rowActionClickHandler"
            @row-click="tableRowClick"
          />
        </div>
      </div>

      <!-- Delete modal -->
      <BaseModal v-if="!loading" title="Delete User" :toDisplay="showDeleteModal" @toggleEvent="toggleDeleteModal">
        <div class="my-10  max-h-96 max-w-lg sm:max-w-full overflow-y-auto">
          <div class="mx-12 flex flex-col text-center">
            <span class="text-2xl font-bold">
              {{ selectedUsers.length }} {{ selectedUsers.length > 1 ? "users" : "user" }} will be removed <br />
            </span>
            <p class="mx-4 pt-4 text-gray-500 lg:text-sm text-lg">

              This cannot be undone. Users will be removed from <br class="hidden sm:block" />
              the subscription and will lose the ability <br class="hidden sm:block" />
              to to dictate and record notes. Invites will <br class="hidden sm:block" />
              be canceled and invite emails will no longer work.<br class="hidden sm:block" />
            </p>
            <div v-if="disableRemove" class="py-2 text-error">
              Warning: you cannot remove all managers from the subscription
            </div>
            <div v-else-if="selfSelected" class="py-2 text-gray-500">
              warning: you have selected yourself, be careful removing users
            </div>
          </div>
          <div class="flex flex-col justify-center mt-2">
            <ul class="alternate pl-0">
              <li v-for="user in selectedUsers" :key="user.title.email"
                  class="px-16 py-2 text-neutral-alt text-base font-semibold flex justify-between">
                <div>{{ user.title.email }} </div> <div class="w-14">
                  {{ !!user.inviteToken ? "Invite" : "User" }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="flex justify-end modal-end p-6">
          <a class="cancel-button" @click="toggleDeleteModal"> CANCEL </a>
          <button
            :disabled="(disableRemove || removePending) ?? undefined"
            class="btn-primary-delete btn-slim"
            @click="deleteUsers">
            Remove Users
          </button>
        </div>
      </BaseModal>
      <!-- Permissions change modal -->
      <BaseModal v-if="!loading && modalPermissions.length > 0" title="Change User Privileges" :toDisplay="showPermissionModal" @toggleEvent="togglePermissionModal">
        <div class="mx-12 mt-10  max-w-lg sm:max-w-full">
          <div class="flex flex-col text-center">
            <span class="text-2xl font-bold">
              You are updating {{ selectedUsers.length }} {{ selectedUsers.length > 1 ? "users" : "user" }}
            </span>
            <p class="text-gray-500 mx-4 pt-4 lg:text-sm text-lg font-semibold">
              This action will overwrite the permissions of <br class="hidden sm:block" />
              these users, giving or removing access to:
            </p>
            <div v-if="selfSelected" class="pt-2 text-gray-500">
              warning: you have selected yourself, <br class="hidden sm:block" /> be careful changing permissions
            </div>
          </div>
          <!-- -permissions select -->
          <div class="ml-6 mt-2">
            <div v-for="pair in modalPermissions" :key="pair.name" class="flex justify-start">
              <input :id="pair.name" v-model="pair.val" type="checkbox" class="tk-check my-2" />
              <label :for="pair.name" class="my-auto ml-3 text-base cursor-pointer capitalize">
                {{ pair.name.replace(/_/g, " ") }}
                <span v-if="pair.name == 'dictation'">
                  ({{ dictationUserResult }}/{{ numberOfUsers }} users)
                </span>
                <span v-else-if="pair.name == 'verified'">
                  ({{ verifiedUserResult }}/{{ verifiedSeatsCount }} users)
                </span>
              </label>
            </div>
            <div class="relative flex-grow justify-center my-2">
              <div class="tooltip text-sm font-normal text-gray-600 underline text-center">
                What do these permissions do?
                <div class="tooltip-text text-left mr-1">
                  <div><b>Dictation: </b> Needed to dictate with Talkatoo. </div>
                  <div><b>User Management: </b> Send invites, update permissions, reset passwords, and remove users.</div>
                  <div><b>Billing Management: </b> Update payment and shipping information, upgrade the subscription, and download invoices. </div>
                  <div v-if="subHasVerifiedSeats">
                    <b>Verified: </b> Verified Scribes will review and correct this user's Notes.
                  </div>
                </div>
              </div>
            </div>
            <div :class="['transition-all text-error',
                          dictationUserResult > numberOfUsers
                            || managerUserResult <= 0
                            || verifiedUserResult > verifiedSeatsCount ? 'visible shake' : 'invisible']">
              <span v-if="verifiedUserResult > verifiedSeatsCount">
                Sorry, this would add more than your <br class="hidden sm:block" />
                limit of Verified enabled users.
              </span>
              <span v-else-if="!managerUserResult <= 0">
                Sorry, this would add more than your <br class="hidden sm:block" />
                limit of dictation enabled users.
              </span>
              <span v-else>
                Sorry, this would remove all the managers <br class="hidden sm:block" />
                You need at least 1 full-access manager.
              </span>
            </div>
          </div>
        </div>
        <div class="flex justify-end modal-end p-6">
          <a class="cancel-button" @click="togglePermissionModal"> CANCEL </a>
          <button
            :class="[dictationUserResult > numberOfUsers
              || managerUserResult <= 0
              || verifiedUserResult > verifiedSeatsCount ? 'disabled' : '', 'btn-primary btn-slim']"
            @click="updatePermissions">
            Update Users
          </button>
        </div>
      </BaseModal>

      <!-- Reset password modal -->
      <BaseModal v-if="!loading" title="Reset User Password" :toDisplay="showResetModal" @toggleEvent="toggleResetModal">
        <div class="my-10  max-h-96 max-w-lg sm:max-w-full overflow-y-auto">
          <div class="mx-12 flex flex-col text-center">
            <span class="text-2xl font-bold">
              {{ nonInviteSelectedUsers.length }} {{ nonInviteSelectedUsers.length > 1 ? "users" : "user" }} will reset their password <br />
            </span>

            <p class="pt-4 text-gray-500 lg:text-sm text-lg mx-auto">
              These users will receive an email that will let<br class="hidden sm:block" />
              them reset their password. Make sure they are<br class="hidden sm:block" />
              expecting this email. Users still in the “invited”<br class="hidden sm:block" />
              status will not receive an email.
            </p>
          </div>
          <div class="flex flex-col justify-center mt-2">
            <ul class="alternate pl-0">
              <li v-for="user in selectedUsers" :key="user.title.email"
                  class="px-16 py-2 text-neutral-alt text-base font-semibold flex justify-between">
                <div>{{ user.title.email }} </div> <div class="w-14">
                  {{ !!user.inviteToken ? "Invite" : "User" }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="flex justify-end modal-end p-6">
          <a class="cancel-button" @click="toggleResetModal"> CANCEL </a>
          <button :disabled="resetPending ?? undefined"
                  class="btn-primary btn-slim"
                  @click="resetUserPasswords">
            Send Password Resets
          </button>
        </div>
      </BaseModal>


      <UpdateModals v-if="userPerms.billing_management && !loadingProduct"
                    :loading="loading"
                    :productList="productList"
                    :subscription="subscription"
                    :countryList="countryList"

                    :billingModalDisplay="billingModalDisplay"
                    :subscriptionModalDisplay="subscriptionModalDisplay"
                    :threeDSecureUpgradeModalDisplay="threeDSecureUpgradeModalDisplay"

                    @toggleBillingModal="toggleBillingModal"
                    @subscriptionUpdate="subscriptionUpdate"
                    @toggleSubscriptionModal="toggleSubscriptionModal"
                    @toggleThreeDSecureModal="toggleThreeDSecureModal"
                    @pendingRequest="pendingRequest"
                    @setLoading="setLoading" />

      <InviteModal v-if="!loading && subscription"
                   :toDisplay="inviteModalDisplay"
                   :users="users"
                   :loading="loading"
                   :invites="invites"
                   :subscription="subscription"
                   @invitetoggleEvent="toggleInviteModal"
                   @inviteEvent="inviteUsers" />
    </div>
  </div>
</template>

<script src="./UserManagement.js"></script>
